// import $ from 'jquery'
import axios from "axios";
import moment from 'moment'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import Vue from "vue";
import vSelect from "vue-select";
// import moment from 'moment'
Vue.component('v-select', vSelect)
export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: "Tableau de bord Conventionnel",
            dateOptions: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            loading: false,
            NAVIRES:[],
            ENTREPOTS: [],
            filtre:{
                date_debut: moment().format('DD/MM/YYYY'),
                date_fin: moment().format('DD/MM/YYYY'),
                id_navire: '',
                nom_entrepot: '',
                colonne_critere: '',
                filter: '',
            },
            data:[],
            data_transporteur: [],
            vuetable: {
                moreParams: {
                    date_debut: moment().format('DD/MM/YYYY'),
                    date_fin: moment().format('DD/MM/YYYY'),
                    id_navire: '',
                    nom_entrepot: '',
                    colonne_critere: '',
                    filter: '',
                },
                fields: [
                    {
                        name: 'date_enlevement_formatted',
                        title: 'Date',
                        sortField: 'date_enlevement_formatted',
                        dataClass: 'text-left',
                        width: '100px'
                    },
                    {
                        name: 'nom_navire',
                        title: 'Navire',
                        sortField: 'nom_navire',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'num_bl',
                        title: 'Bill of Lading',
                        sortField: 'num_bl',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'declaration_bl',
                        title: 'N° Declaration',
                        sortField: 'declaration_bl',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'nom_entrepot',
                        title: 'Entrepôt',
                        sortField: 'nom_entrepot',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'reference_article',
                        title: 'Marque',
                        sortField: 'reference_article',
                        dataClass: 'text-left',
                    },
                    {
                        name: 'qte_initial',
                        title: 'Qte I',
                        sortField: 'qte_initial',
                        dataClass: 'text-right',
                    },
                    {
                        name: 'qte_t',
                        title: 'Qte Sortie',
                        sortField: 'qte_t',
                        dataClass: 'text-right',
                    },
                    {
                        name: 'qte_sortie_bb',
                        title: 'nbre BB',
                        sortField: 'qte_sortie_bb',
                        dataClass: 'text-right',
                    },
                    {
                        name: 'qte_sortie_nbre_sac',
                        title: 'nbre Sac',
                        sortField: 'qte_sortie_nbre_sac',
                        dataClass: 'text-right',
                    },

                    {
                        name: 'qte_restant',
                        title: 'Qte Restant',
                        sortField: 'qte_restant',
                        dataClass: 'text-right',
                    },

                ],
                sortOrder: [
                    {field: 'id', direction: 'asc'}
                ],
                css: {
                    table: {
                        // tableClass: 'table table-striped table-bordered table-hovered',
                        tableClass: 'table-responsive w-100 d-block d-md-table table table-bordered table-hover',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
        }
    },

    methods: {
        getnavire() {
            axios.get(this.BASE_URL + '/navire/getall').then((response) => {
                    this.NAVIRES = response.data;
            })
        },

        onRowClass(dataItem, index) {
            if (index == "") console.log("");

            if (Number(dataItem.solde) < 0) {
                // return 'text-danger text-bold';
            }
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        getData(){
            let formulaire = new FormData();
            formulaire.append('date_debut',this.filtre.date_debut);
            formulaire.append('date_fin',this.filtre.date_fin);
            formulaire.append('nom_navire',this.filtre.id_navire);
            formulaire.append('colonne_critere',this.filtre.colonne_critere);
            formulaire.append('filter',this.filtre.filter);

            /*axios.post(this.BASE_URL + '/suivieenlevementdetail/tableaubord',formulaire).then((response) => {

                if (response.data.error === ''){
                    this.data = response.data.data;
                }else{
                    console.log(response.data.error)
                }
            });*/
            this.recapTransporteur(formulaire);
        },

        recapTransporteur(formulaire) {
            axios.post(this.BASE_URL + '/suivieenlevementdetail/recaptransporteur',formulaire).then((response) => {
                if (response.data.error === ''){
                    console.log(response.data.data)
                    this.data_transporteur = response.data.data;
                }else{
                    console.log(response.data.error)
                }
            })

        },

        // Calculer le rowspan total pour un BL
        getTotalRowSpan(bl) {
            return bl.declarations.reduce((total, decl) => total + decl.rowspan, 0);
        },

        // Formater les nombres
        formatNumber(value) {
            return new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(value || 0);
        },

        setFilter() {
            this.vuetable.moreParams.date_debut = this.filtre.date_debut;
            this.vuetable.moreParams.date_fin = this.filtre.date_fin;
            this.vuetable.moreParams.id_navire = this.filtre.id_navire;
            this.vuetable.moreParams.nom_entrepot = this.filtre.nom_entrepot;
            this.vuetable.moreParams.colonne_critere = this.filtre.colonne_critere;
            this.vuetable.moreParams.filter = this.filtre.filter;
            Vue.nextTick(() => this.$refs.vuetable.refresh());

            this.getData();
        },
        resetFilter() {
            this.filtre={
                date_debut: moment().format('DD/MM/YYYY'),
                date_fin: moment().format('DD/MM/YYYY'),
                id_navire: '',
                nom_entrepot: '',
                colonne_critere: '',
            };
            this.setFilter();
        },

        fetchEntrepot() {
            axios.get(this.BASE_URL + '/entrepot/getall').then((response) => {
                this.ENTREPOTS = response.data;
            })
        },
        async printSuivieenlevement() {
            const element = JSON.stringify(this.filtre)
            let formulaire = new FormData();
            formulaire.append('element', element);
            const response = await axios.post(this.BASE_URL+"/suivieenlevementdetail/printsuivieenlevement", formulaire)
            if(response.data.success== true){
                window.open(response.data.file_url, '_blank');
            }
        },

        async printTransporteur() {

            const element = JSON.stringify(this.filtre)
            let formulaire = new FormData();
            formulaire.append('element', element);
            const response = await axios.post(this.BASE_URL+"/suivieenlevementdetail/printtransporteur", formulaire)
            if(response.data.success== true){
                window.open(response.data.file_url, '_blank');
            }
        },
    },

    computed: {
        processedData() {
            return this.data.map(bl => {
                // Calculer le rowspan total pour le BL
                const totalRowSpan = bl.declarations.reduce((sum, decl) => sum + decl.rowspan, 0);

                return {
                    ...bl,
                    totalRowSpan,
                    declarations: bl.declarations.map(declaration => {
                        return {
                            ...declaration,
                            marques: declaration.marques.map(marque => {
                                // Calculer les valeurs restantes
                                const restant = {
                                    qte_t: parseFloat(marque.qte_initial) - parseFloat(marque.sortie.qte_t),
                                    nbre_sac: 0 - parseFloat(marque.sortie.nbre_sac),
                                    nbre_big_bag: 0 - parseFloat(marque.sortie.nbre_big_bag)
                                };

                                return {
                                    ...marque,
                                    total: parseFloat(marque.qte_initial),
                                    restant,
                                    isDuplicate: false // Par défaut, aucun doublon
                                };
                            })
                        };
                    })
                };
            });
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.motCle = "";
        this.criteriacol = "";

    },
    mounted: function() {
        this.getData();
        this.getnavire();
        this.fetchEntrepot();
    }
}
